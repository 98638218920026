import React from 'react';

import { get } from 'lodash';
import { useSelector } from 'react-redux';

import { enrolStartSelfieAuthMFAAPI, enrolVerifySelfieAuthMFAAPI } from '../../../api/mfa';
import { useErrorToast } from '../../../utils/toast';
import CustomButton from '../../Common/Buttons/CustomButton';

function AddSelfieAuthMFA() {
  const showErrorToast = useErrorToast();
  const email = useSelector((state) => state.user.userDetails.email);

  const enrolSelfeAuthMFA = async (transactionId) => {
    try {
      await enrolVerifySelfieAuthMFAAPI({ id: transactionId });
    } catch (err) {
      showErrorToast({ message: 'Server Error' });
    }
  };

  const callback = (res) => {
    const { status, transactionId } = res;
    if (status === 'auto_approved') {
      enrolSelfeAuthMFA(transactionId);
    } else {
      showErrorToast({ message: 'Could not Verify at the moment' });
    }
  };

  async function runGlobal(token, id) {
    const config = new window.HyperKycConfig(token, 'selfie_auth_mfa_enrol', id);
    config.setInputs({
      transactionId: id
    });
    window.HyperKYCModule.launch(config, callback);
  }

  // Temporary hashing - change to server side later
  async function sha256(message) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message);

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string
    const hashHex = hashArray.map((b) => `00${b.toString(16)}`.slice(-2)).join('');
    return hashHex;
  }

  const addSelfieAuthMFAOnClick = async () => {
    try {
      const res = await enrolStartSelfieAuthMFAAPI();
      const token = get(res, 'data.token');
      const id = `${await sha256(email)}_${Date.now()}`;
      runGlobal(token, id);
    } catch (err) {
      const code = get(err, 'response.data.code');
      if (code === 'mfa/invalid-token' || code === 'mfa/expired-token') {
        showErrorToast({ message: 'MFA Session expired' });
        return;
      }
      showErrorToast({ message: 'Server Error' });
    }
  };

  return (
    <CustomButton
      id="user_management__header__selfie_auth_mfa__button"
      theme="purple"
      onClick={addSelfieAuthMFAOnClick}>
      Add Selfie Auth MFA
    </CustomButton>
  );
}

export default AddSelfieAuthMFA;
