import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SELF_SERVE_BASE_URL}/api/mfa`,
  withCredentials: true
});

export const getAvailableFactorsAPI = () => axiosInstance.get('/factors/available');

export const getRegisteredFactorsAPI = () => axiosInstance.get('/factors/registered');

export const enrolStartSelfieAuthMFAAPI = () => axiosInstance.get('/enrol/start/selfie/auth');

export const enrolVerifySelfieAuthMFAAPI = ({ id }) =>
  axiosInstance.post('/enrol/verify/selfie/auth', { id });
