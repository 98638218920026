import React from 'react';

import AddSelfieAuthMFA from './AddSelfieAuthMFA';
import InviteUsersButton from './InviteUsersButton';

function Header() {
  return (
    <div id="user_management__header__container">
      <h2>Users</h2>
      <AddSelfieAuthMFA />
      <InviteUsersButton />
    </div>
  );
}

export default Header;
