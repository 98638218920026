import OptionCell from './OptionCell';

const generateRoleOptions = (rolesData, authenticatedUserRoleId) =>
  Object.entries(rolesData).map(([roleId, { name, description }]) => ({
    componentRenderer: () => {
      const roleName = authenticatedUserRoleId === roleId ? `${name} (like you)` : name;
      return <OptionCell name={roleName} description={description} />;
    },
    label: name,
    value: roleId
  }));

export default generateRoleOptions;
