import React from 'react';

import { Spinner } from 'clm-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import getPartialErrorMessage from './utils';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/icons/arrowDown.svg';
import { authorizedProductOption } from '../../../../utils/constants';
import CommonMultiSelect from '../../../Common/Inputs/CommonMultiSelect';
import CommonSelect from '../../../Common/Inputs/CommonSelect';
import generateRoleOptions from '../utils';

function Body({
  isLoading,
  didFailPartially,
  partialErrorData,
  emailInvalidMessage,
  selectedRole,
  emailRawText,
  handleSelect,
  handleTextAreaType,
  selectedProducts,
  handleSelectedProduct
}) {
  const rolesData = useSelector((state) => state.iam.rolesData);
  const userDetails = useSelector((state) => state.user.userDetails);
  const { role: authenticatedUserRole } = userDetails;
  const options = generateRoleOptions(rolesData, authenticatedUserRole);

  if (didFailPartially) {
    return (
      <div id="invite_users_modal__body" className="partial_error">
        <div id="invite_users_modal__body__partial_error">
          <p>Error inviting following users</p>
          {partialErrorData.map(({ email, statusCode }) => (
            <p key={email} className="error">
              {email} - <span>{getPartialErrorMessage(statusCode)}</span>
            </p>
          ))}
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div id="invite_users_modal__body" className="loading">
        <Spinner size="small" />
      </div>
    );
  }

  return (
    <div id="invite_users_modal__body">
      <div id="invite_users_modal__body__email">
        <p>Email</p>
        <textarea
          className={emailInvalidMessage && 'error'}
          rows={4}
          value={emailRawText}
          onChange={handleTextAreaType}
          placeholder="email1@example.com, email2@example.com..."
        />
        {emailInvalidMessage && <p className="error">{emailInvalidMessage}</p>}
      </div>
      <div id="invite_users_modal__body__role">
        <p>Role</p>
        <CommonSelect
          id="invite_users_modal__body__role__select"
          options={options}
          handleChange={handleSelect}
          label="choose a role for the invitees"
          Icon={ArrowDownIcon}
          initValue={selectedRole}
        />
      </div>
      <div id="invite_users_modal__body__role">
        <p>Authorized products</p>
        <CommonMultiSelect
          id="invite_users_modal__body__role__select"
          options={authorizedProductOption}
          label="Choose the authorized products"
          Icon={ArrowDownIcon}
          selectedProducts={selectedProducts}
          handleChange={handleSelectedProduct}
        />
      </div>
    </div>
  );
}

Body.defaultProps = {
  isLoading: false,
  selectedProducts: []
};

Body.propTypes = {
  isLoading: PropTypes.bool,
  didFailPartially: PropTypes.bool.isRequired,
  partialErrorData: PropTypes.array.isRequired,
  emailInvalidMessage: PropTypes.string.isRequired,
  selectedRole: PropTypes.string.isRequired,
  emailRawText: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleTextAreaType: PropTypes.func.isRequired,
  selectedProducts: PropTypes.array,
  handleSelectedProduct: PropTypes.func.isRequired
};

export default Body;
