import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import SearchIcon from '../../../assets/icons/search.svg';
import './Inputs.scss';

function CommonSearchBar({
  id,
  initValue,
  handleSearch,
  placeholder,
  onFocus,
  isIconVisible,
  isInputVisible
}) {
  const [isSearchFocussed, setIsSearchFocussed] = useState(false);
  const [value, setValue] = useState('');
  const [isInputOpen, setIsInputOpen] = useState(true);

  const handleFocus = (isFocussed) => {
    if (onFocus) onFocus(isFocussed);
    setIsSearchFocussed(isFocussed);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const onIconClick = () => {
    setIsInputOpen(!isInputOpen);
  };
  useEffect(() => {
    if (isInputVisible !== undefined) setIsInputOpen(isInputVisible);
  }, [isInputVisible]);

  useEffect(() => {
    handleSearch(value);
  }, [value]);

  useEffect(() => {
    if (initValue) setValue(initValue);
  }, [initValue]);

  return (
    <div id={id} className={`common__searchbar ${isSearchFocussed ? 'focus' : ''}`}>
      {isIconVisible && <img src={SearchIcon} alt="s" onClick={onIconClick} aria-hidden />}
      <input
        value={value}
        placeholder={placeholder}
        onFocus={() => handleFocus(true)}
        onBlur={() => handleFocus(false)}
        onChange={handleChange}
        className={`${!isInputOpen && 'close'}`}
      />
    </div>
  );
}

CommonSearchBar.defaultProps = {
  id: '',
  initValue: '',
  placeholder: 'Search',
  onFocus: null,
  isIconVisible: true,
  isInputVisible: true
};

CommonSearchBar.propTypes = {
  id: PropTypes.string,
  initValue: PropTypes.string,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  isIconVisible: PropTypes.bool,
  isInputVisible: PropTypes.bool,
  handleSearch: PropTypes.func.isRequired
};

export default CommonSearchBar;
